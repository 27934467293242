@import "../node_modules/reset-css/reset.css";
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: 'Absolut';
  src: url('./fonts/Absolut-Regular.eot');
  src: url('./fonts/Absolut-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Absolut-Regular.woff2') format('woff2'),
      url('./fonts/Absolut-Regular.woff') format('woff'),
      url('./fonts/Absolut-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Absolut';
  src: url('./fonts/Absolut-Bold.eot');
  src: url('./fonts/Absolut-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Absolut-Bold.woff2') format('woff2'),
      url('./fonts/Absolut-Bold.woff') format('woff'),
      url('./fonts/Absolut-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html, body, button, input {
  font-family: 'Absolut';
}
sub {
  vertical-align: sub;
  font-size: 80%;
}

$gold: #D0C089;
$gold-shadow: #EEB238;
$dark: #4A4A4A;
$dark-bg: #18181B;
$pink: #BD1E40;
$pink-button: #EE2852;
$dark-pink: #9F1A37;

$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 992px,
  x-large: 1200px
);

.section {
  height: 100vh;

  @media (max-width: map-get($breakpoints, medium) - 1) {
    height: auto !important;
  }
}

.logo {
  position: fixed;
  top: 50px;
  left: 70px;
  z-index: 1000;
  width: 70px;
  height: 69px;

  @media (max-width: map-get($breakpoints, large) - 1) {
    width: 50px;
    height: 49px;
    top: 22px;
    left: 15px;
  }
}

.grains {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.7;
  pointer-events: none;
  z-index: 1001;
  width: 100%;
  height: 100%;
}

.hamburger {
  display: none;

  @media (max-width: map-get($breakpoints, large) - 1) {
    display: block;
    position: fixed;
    top: 25px;
    right: 15px;
    z-index: 2000;
    width: 32px;
    height: 28px;
  }

  @media (max-width: map-get($breakpoints, medium) - 1) {
    width: 16px;
    height: 14px;
  }
}

.button {
  appearance: none;
  border: 1px solid $pink-button;
  background: transparent;
  font-size: 14px;
  font-weight: bold;
  color: white;
  line-height: 16px;
  padding: 14px 50px 16px;
  transition: all .2s;
  border-radius: 23px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;

  @media (max-width: map-get($breakpoints, medium) - 1) {
    background: $pink-button;
  }

  &:hover {
    background: $pink-button;
  }
}

.nav-button {
  border: none;
  appearance: none;
  background: $gold;
  display: inline-flex;
  align-items: center;
  line-height: 16px;
  padding: 30px 15px;
  transition: all .2s;
  cursor: pointer;

  svg {
    transition: transform .2s;
  }

  &.left {
    svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: darken($gold, 5%);

    &.right {
      svg {
        transform: translateX(5px);
      }
    }

    &.left {
      svg {
        transform: rotate(180deg) translateX(5px);
      }
    }
  }
}

.top-menu {
  position: fixed;
  z-index: 100;
  right: 125px;
  top: 55px;
  color: white;
  z-index: 1500;
  width: calc(100vw - 400px);

  @media (max-width: map-get($breakpoints, x-large) - 1) {
    right: 75px;
  }

  @media (max-width: map-get($breakpoints, large) - 1) {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark;
    transition: transform .5s;
    transform: translateX(100%);
    padding: 50px 20px;
    width: auto;

    &.active {
      transform: translateX(0);
    }
  }

  ul {
    display: flex;
    align-items: center;

    @media (max-width: map-get($breakpoints, large) - 1) {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      display: block;

      &:not(:last-child) {
        margin-right: 60px;

        @media (max-width: map-get($breakpoints, x-large) - 1) {
          margin-right: 25px;
        }

        @media (max-width: map-get($breakpoints, large) - 1) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &:first-child {
        margin-right: 80px;
        position: relative;

        @media (min-width: map-get($breakpoints, large)) {
          &:after {
            content: "";
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            width: 5px;
            height: 15px;
            background: $pink;
          }
        }
      }

      &:last-child {
        margin-left: auto;
        position: relative;

        @media (max-width: map-get($breakpoints, large) - 1) {
          margin-left: 0;
        }

        @media (min-width: map-get($breakpoints, large)) {
          &:after {
            content: "";
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            width: 5px;
            height: 15px;
            background: $pink;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: white;
        transition: color .2s;
        font-size: 16px;
        position: relative;
        font-weight: bold;

        &.active {
          &:after {
            background: $pink;
          }

          .icon-wrapper {
            background: #B61E3E;
            box-shadow: 0 2px 14px 1px #B61E3E;
          }
        }

        @media (min-width: map-get($breakpoints, large)) {
          &:after {
            content: "";
            position: absolute;
            left: 5px;
            right: -20px;
            bottom: 14px;
            z-index: -1;
            height: 5px;
            background: transparent;
            transition: all 0.2s;
          }
        }

        .icon-wrapper {
          width: 40px;
          height: 40px;
          position: relative;
          margin-right: 15px;
          border-radius: 20px;
          background: transparent;
          transition: all 0.2s;

          @media (max-width: map-get($breakpoints, large) - 1) {
            display: none;
          }
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          path {
            transition: fill .2s;
            fill: white;
          }
        }
      }
    }
  }
}

.home {
  padding: 140px 80px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #18181B;

  @media (max-width: map-get($breakpoints, medium) - 1) {
    padding: 25px 20px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    background: white url(./images/bg_mobile.png) no-repeat center;
  }

  .content {
    width: 40%;

    @media (max-width: map-get($breakpoints, large) - 1) {
      width: 70%;
    }

    @media (max-width: map-get($breakpoints, medium) - 1) {
      width: auto;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 125px 40px 0;
    }

    h2 {
      color: white;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        order: 2;
        font-size: 10px;
        margin-bottom: 75px;
      }
    }

    .svg-text {
      margin-bottom: 35px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: none;
      }
    }

    .svg-text-mobile {
      display: none;
      margin-bottom: 10px;
      order: 1;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: block;
      }
    }

    p {
      color: white;
      margin-bottom: 45px;
      font-size: 12px;
      line-height: 26px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: none;
      }
    }

    .button {
      @media (max-width: map-get($breakpoints, medium) - 1) {
        order: 3;
        margin-bottom: 80px;
      }
    }

    .scroll {
      display: none;
      transform: rotate(-90deg);
      order: 4;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: block;
      }
    }
  }

  .home-image {
    flex-shrink: 1;
    height: calc(100% - 140px);
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 50%;

    @media (max-width: map-get($breakpoints, large) - 1) {
      display: none;
    }
  }
}

.products {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 110px 0 25px;
  position: relative;
  background:  $dark-bg url(./images/circles.png) no-repeat;
  background-size: calc(100vh - 135px);
  background-position: 90px 110px;

  @media (max-width: map-get($breakpoints, medium) - 1) {
    padding: 0 0 70px;
    background-size: 100vw;
    background-position: 0 50px;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    background: transparent;
    border: none;
    padding: 0;
    z-index: 1050;
    cursor: pointer;

    @media (max-width: map-get($breakpoints, medium) - 1) {
      display: none;
    }

    .text {
      color: white;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
      display: block;
    }

    .arrow {
      display: block;
      background: #AA1B39;
      padding: 15px 45px;
    }

    &:focus {
      outline: none;
    }
  }

  .prev {
    left: 0;
    text-align: right;

    .arrow {
      border-radius: 0 8px 8px 0;
    }
  }

  .next {
    left: auto;
    right: 0;
    text-align: left;

    .arrow {
      border-radius: 8px 0 0 8px;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .product {
    display: flex;
    align-items: center;
    padding: 0 180px 0 90px;

    @media (max-width: map-get($breakpoints, large) - 1) {
      padding: 0 110px 0 0;
    }

    @media (max-width: map-get($breakpoints, medium) - 1) {
      padding: 50px 40px 0;
      flex-direction: column;
    }

    .title-mobile {
      display: none;
      font-size: 26px;
      color: white;
      font-weight: bold;
      line-height: 30px;
      margin-bottom: 45px;
      position: relative;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: block;
      }

      &:after {
        content: "";
        bottom: 5px;
        left: -10px;
        right: -10px;
        height: 1px;
        background: $pink;
        position: absolute;
      }
    }

    .dots {
      display: none;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: flex;
      }

      .dot {
        height: 8px;
        width: 8px;
        border-radius: 4px;
        background: #D6D6D6;
        cursor: pointer;

        &.active {
          background: $pink;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .pages {
      display: none;
      text-align: center;
      color: white;
      font-weight: bold;
      margin-bottom: 25px;
      font-size: 10px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: block;
      }
    }

    .images {
      height: 100%;
      position: relative;

      @media (max-width: map-get($breakpoints, large) - 1) {
        height: 50%;
      }

      .circles {
        height: 100%;
        width: auto;
        opacity: 0;

        @media (max-width: map-get($breakpoints, medium) - 1) {
          display: none;
        }
      }

      .photo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: auto;

        @media (max-width: map-get($breakpoints, medium) - 1) {
          position: relative;
          height: auto;
          transform: none;
          top: auto;
          left: auto;
          width: 100%;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: -40px;

      @media (max-width: map-get($breakpoints, large) - 1) {
        margin-left: -80px;
      }

      @media (max-width: map-get($breakpoints, medium) - 1) {
        margin-left: 0;
        text-align: center;
        align-items: center;
      }
    }

    .title {
      color: white;
      font-size: 60px;
      font-weight: bold;
      line-height: 67px;
      margin-bottom: 40px;

      @media (max-width: map-get($breakpoints, large) - 1) {
        font-size: 42px;
        line-height: 45px;
      }

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: none;
      }
    }

    .text {
      font-size: 14px;
      color: white;
      line-height: 26px;
      margin-bottom: 35px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        font-size: 11px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }

    .table-title {
      color: #DD264D;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 40px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        margin-bottom: 15px;
      }
    }

    table {
      min-width: 60%;
      margin-bottom: 40px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        margin-bottom: 15px;
      }

      td, th {
        color: white;
        font-size: 14px;
        padding: 5px 0;
        text-align: left;
  
        &:not(:last-child) {
          padding-right: 15px;
        }
      }

      thead {
        tr {
          border-bottom: 1px solid $dark-pink;

          th {
            padding-bottom: 10px;
          }
        }
      }

      tbody {
        tr {
          &:first-child {
            td {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}

.contacts {
  padding: 110px 100px 50px;
  position: relative;
  background: $dark-bg;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media (max-width: map-get($breakpoints, medium) - 1) {
    flex-direction: column;
    padding: 0 40px;
  }

  .texts {
    z-index: 10;

    .subtitle {
      color: $dark-pink;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: none;
      }
    }

    .title {
      font-size: 70px;
      color: white;
      font-weight: bold;
      line-height: 65px;
      margin-bottom: 20px;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 40px;

        br {
          display: none;
        }
      }
    }

    .address {
      color: white;
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        display: none;
      }
    }
  }

  .form {
    border: 15px solid $dark-pink;
    margin-left: -150px;
    padding: 100px 80px 90px 250px;

    @media (max-width: map-get($breakpoints, large) - 1) {
      padding: 50px 60px 45px 180px;
    }

    @media (max-width: map-get($breakpoints, medium) - 1) {
      border-width: 4px;
      padding: 20px;
      margin-left: 0;
      width: 100%;
      margin-bottom: 50px;
      border-color: #F02E5C;
    }

    form {
      max-width: 100%;
      min-height: 275px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media (min-width: map-get($breakpoints, medium)) {
        max-width: 380px;
      }
    }

    .thank-you {
      display: block;
      font-size: 50px;
      color: white;
      font-weight: bold;
      text-align: center;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        font-size: 24px;
      }
    }

    .title {
      font-size: 16px;
      color: white;
      line-height: 1;
      margin-bottom: 35px;
      font-weight: bold;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        font-size: 12px;
        margin-bottom: 20px;
      }
    }

    input, textarea {
      width: 100%;
      appearance: none;
      border: none;
      border-bottom: 1px solid #979797;
      background: transparent;
      color: white;
      font-size: 14px;
      line-height: 1;
      padding: 6px 0;
      font-weight: bold;
      margin-bottom: 30px;
      transition: border 0.2s;

      &:focus {
        outline: none;
        border-color: $dark-pink;
      }

      @media (max-width: map-get($breakpoints, medium) - 1) {
        min-width: 0;
        font-size: 8px;
      }
    }

    textarea {
      min-height: 100px;
    }

    .form-button {
      appearance: none;
      border: none;
      background: transparent;
      border-bottom: 1px solid #979797;
      color: $pink-button;
      font-size: 16px;
      line-height: 1;
      padding: 5px 10px 5px 0;

      @media (max-width: map-get($breakpoints, medium) - 1) {
        font-size: 8px;
        font-weight: bold;
        padding: 2px 5px 2px 0;
      }
    }

    .field {
      position: relative;
      width: 100%;

      .error {
        color: white;
        position: absolute;
        font-size: 14px;
        bottom: 10px;
        left: 0;
        white-space: nowrap;
      }
    }

    .form-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;

      .field {
        @media (min-width: map-get($breakpoints, large)) {
          width: calc((100% - 50px) / 2);
        }

        &:not(:last-child) {
          margin-right: 50px;

          @media (max-width: map-get($breakpoints, large) - 1) {
            margin-right: 0;
          }
        }

        @media (max-width: map-get($breakpoints, large) - 1) {
          width: 100%;
        }
      }
    }
  }

  footer {
    display: none;
    text-align: center;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    padding: 10px;
    position: relative;

    @media (max-width: map-get($breakpoints, medium) - 1) {
      display: block;
    }
  }

  .letters {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: map-get($breakpoints, medium) - 1) {
      display: none;
    }
  }
}